/*sns login*/
.form_btn{
  width:90%;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  gap:10px;

}
.form_btn button:nth-child(1){
}
.form_btn button,
.naver_btn{
  width:100% !important;
  height: 49px;
  line-height: 49px;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
}
.naver_btn{
  background-color: green;
  color:white;
  cursor: pointer;
}
.Member{
  position: relative;
  width:90%;
  margin:0 auto;
  max-width:500px;
  margin:0 auto;
  padding-top:30px;
  padding-bottom:60px;
}

.register_info_wrap{
}
.register_info_wrap_tit{
    font-size: 24px;
    margin-top:60px;
    margin-bottom: 26px;
    font-weight: 700;
}
.register_info_desc{
    color:#A7A7A7;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 25px;
}
.register_info_notice{
    color:#C1C1C1;
    font-size:13px;
    line-height: 18px;
    margin-top:23px;
}
#info_wrap{
}
.registerInfo{
    /* background-color: #f9f9f9; */
    width:100%;
    min-height: 100vh;
}
.register_first_backbtn{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    z-index: 99;
    padding: 32px 18px 12px 18px;
    padding: calc(32px + env(safe-area-inset-top)) 18px 12px 18px;
    padding: calc(32px + constant(safe-area-inset-top)) 18px 12px 18px;
    text-align: left;
}
.register_first_backbtn .head_left img{
    width: 12px;
}
.con_chk_bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 9999;
}
.con_chk_alert {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
}
.con_chk_alert_head {
    background: #67CCBF;
    color: #fff;
    padding: 10px 20px;
}
.con_chk_alert_content {
    padding: 10px 20px;
}
.con_chk_alert_button {
    padding: 0 20px 10px;
    display: flex;
    justify-content: flex-end;
}
.con_chk_alert_button a {
    display: block;
    padding: 6px 20px;
    background: #67CCBF;
    border-radius: 8px;
    color: #fff;
}
#wrap {
    position: relative;
}

#layerPopup {
    position: fixed;
    top: 0px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    z-index: 999;
    margin: 0 auto;
    display: none;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    z-index: 99999999999;
    padding-top:35px;
}
#layerPopup.open {
    display: block;
}
#layerPopup .header {
    position: relative;
    height: 50px;
    line-height: 50px;
    font-weight: 800;
    text-align: center;
    color: #fff;
    border-radius: 5px 5px 0 0;
}

#layerPopup .header .modal_close {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 10px;
    right: 10px;
}

#layerPopup .header .modal_close > a {
    display: block;
    width: 100%;
    height: 100%;
    /*  */
    text-indent: -9999px;
}

#layerPopup .header .btn_close_layer {
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
    color: black;
}

#layerPopup .layer-containers {
    position: absolute;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f0f0f0;
    padding-top:80px;
}
#layerPopup .layer-containers .inner {
    height: 100%;
    border-radius: 0 0 5px 5px;

}
#layerPopup .layer-containers .box {
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    padding: 20px 10px 100px 20px;
    overflow-y: auto;
    text-align: left;
    white-space: pre-line;
}
#layerPopup img {
    width: 100%;
    object-fit: fill;

}

div.div_join_all_ok {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
}

.div_join_all_ok ul {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.div_join_all_ok li {
    display: flex;
    align-items: center;
    margin:0 !important
}

.bo_v_option .title {
    text-align: left;
    font: normal normal normal 16px/22px Agothic;
    letter-spacing: 0;
    color: #343434;
}

#bo_w .bo_v_option li {
    display: inline-block;
    float: left;
    text-align: left;
    margin: 0 5px 0 0;
}

ul.bo_v_option input {
    display: none;
}
.terms_chk_box {
    padding: 15px 0 15px 10px;
    border-radius: 14px;
    margin-bottom: 20px;
    background-color:white;
}
.all_chk_box{
    background: #F7F7F7;
}
.div_join_all_ok .chk_list li{
    background-color: white;
}
.terms_chk_box input[type="checkbox"]+label {
    position: relative;
    color: #333333;
}

ul.bo_v_option input+label {
    height: 24px;
    background: url(../../img/register/check_off.png) no-repeat;
    background-size: contain;
    padding-left: 32px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    margin-right: 10px;
    width: 100%;
    font-weight:800;
    font-size: 14px;
}

ul.bo_v_option input+label span {
    font: normal normal normal 13px/18px Agothic;
    color: #989898;
    margin-right: 23px;
}

ul.bo_v_option input:checked+label {
    background: url(../../img/register/check_on.png) no-repeat;
    background-size: contain;
}

.notice_small {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #989898;
    margin-top: 10px;
    margin-bottom: 10px;
}

.terms_chk_box span {
    width: 9px;
    display: inline-block;
    margin: 0 0 -2px 3px;
    white-space: nowrap;
}

.terms_chk_box img {
    width: 100%;
    display: block;
}

.line {
    border: 1px solid #C1C1C1;
    margin-bottom: 10px;
}

.div_join_all_ok .chk_list {
}

li.policy_agree {
    /* margin-left: 30px; */
}
.register_step label {
    font-size: 17px;
    line-height: 18px;
    margin-bottom: 12px;
}
#select_state1,
#select_state2,
#select_state3{
    margin-bottom: 25px;
    box-shadow: 3px 3px 15px #0000001A;
}
.register_title {
    display: block;
    font-size: 17px;
    color: #A7A7A7;
    font-weight: 800;
    margin-top: 33px;
    margin-bottom: 12px;
}
.ip_box8 {
    border-radius: 8px;
    border: 1px solid #C1C1C1;
    box-shadow: 3px 3px 6px #9898981A;
    padding: 14px;
    font-size: 14px;
    color: #343434;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    box-sizing: border-box;
}
.ip_box8:focus {
    outline: none;
}
.ip_box8::placeholder {
    color: #989898;
    font-size: 14px;
}
.ip_box8.ip_arrow {
    /*  */
}
.ip_box8 .ip_box_option {
    color: #989898;
}
.ip_box12 {
    border-radius: 12px;
    border: 1px solid #C1C1C1;
    box-shadow: 3px 3px 6px #9898981A;
    padding: 14px;
    color: #343434;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    box-sizing: border-box;
}
.ip_box12::placeholder {
    color: #989898;
    font-size: 14px;
}
.editor {
    border-radius: 12px;
    border: 1px solid #C1C1C1;
    box-sizing: border-box;
    padding: 15px 14px;
    font-size: 14px;
    box-shadow: 3px 3px 6px #9898981A;
    height: 128px !important;
}
.editor:focus {
    outline: none;
}
.editor::placeholder {
    color: #989898;
}

#ft {
    display: none;
}

#bo_w .write_div {
    margin: 0 0 14px;
}
.write_div h3 {
    font-size: 16px;
    padding: 16px 0 4px;
}
.write_div input {
    margin-bottom: 6px;
}

.hosp_check .hosp_chk {
    margin-right: 24px;
}
.hosp_check .hosp_chk input {
    display: none;
}
.hosp_check .hosp_chk label {
    height: 24px;
    display: flex;
    align-items: center;
}
.hosp_check .hosp_chk input + label {
    padding-left: 30px;
    /*  */
    background-size: 24px 24px;
    background-position: left 0 center;
}
.hosp_check .hosp_chk input:checked + label {
    /*  */
    background-size: 24px 24px;
    background-position: left 0 center;
}

.date_wrap input {
    /*  */
    background-size: 24px 24px;
    background-position: right 10px center;
}

.sl_box8 {
    padding: 14px;
    box-shadow: 3px 3px 6px #9898981A;
    width: 100%;
    line-height: 1;
    border: 1px solid #C1C1C1;
    border-radius: 8px;
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /*  */
    background-size: 15px 15px;
    background-position: right 14px center;
    color: #989898;
}

.wr_content {
    margin: 22px 0 10px;
}
.search_hospital_btn {
    position: relative;
}
.search_hospital_btn button {
    background: transparent;
    border: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 43%;
    transform: translateY(-50%);
}
.search_hospital_btn button img {
    width: 100%;
}

.hobox {
    position: fixed;
    background: #fff;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    box-sizing: border-box;
    left: 0;
}
.wan {
    background-color: red;
}
.hsldiv a {
    background-color: #32D1D4;
}
.info_div .info_content {
    border: 1px solid #ccc;
    background: #fff;
    color: #343434;
    vertical-align: middle;
    border-radius: 8px;
    padding: 7px;
    height: 100px;
    display: block;
    overflow-y: scroll;
    margin-bottom: 5px;
    box-shadow: 3px 3px 6px #9898981a;
    line-height: 15px;
}

/*------------회원가입------------*/
#ft {
    display: none;
}

.register {
    overflow: hidden;
}

.mobile_wrap {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    max-width: 500px;
}
.mobile_wrap #info_wrap {
}
.mobile_wrap h2 {
    font-size: 16px;
    padding: 24px 0;
}

.mobile_wrap ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0 0;
    border-radius: 20px;
}

.mobile_wrap ul li {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #C1C1C1;
    border-radius: 8px;
    padding: 20px 0;
    margin-bottom: 13px;
}

.mobile_wrap ul li span {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobile_wrap ul li p {
    font-size: 18px;
    /* padding-top: 10px; */
}
.mobile_wrap ul li img {
    width: 40px;
}
.mobile_wrap .txt_wrapper {
    padding-top: 20px;
}
.txt_wrap_tit {
    font-weight: 800;
}
.txt_wrap_img {
    width:90%;
    margin:0 auto;
}
.txt_wrap_img img{
    width:100%;
}
.mobile_wrap .img_wrap p{
    padding-top:0 !important;
    font-weight: 800 !important;
}
.mobile_wrap .txt {
    border-radius: 8px;
    padding: 18px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 20px;
    line-height: 20px;
    background-color: #F9F9F8;
    color: #1D1D1D;
}
#cert_info {
    position: relative;
    bottom: 0;
}
.mobile_wrap button {
    border: none;
    padding: 18px 0;
    width: 100%;
    border-radius: 23px;
    color: #fff;
    line-height: 1;
    /* box-shadow: 3px 3px 6px rgba(152, 152, 152, 0.5); */
    font-size: 17px;
    font-weight: 800;
    background-color: #32D1D4;
    margin-top: 30px;
}

.mobile_wrap .txt_sub {
    text-align: left;
    display: flex;
    line-height: 16px;
    font-size: 12px;
    color: #FF456B;
}
.mobile_wrap .kcp_chk_notice{
    text-align: center;
}
.mobile_wrap .txt_sub span {
    display: block;
    margin-right: 4px;
}
#register_form{
    /* padding:15px; */
}
.register_form_inner {
    background: #fff;
    border: none;
}

.register_slide_bar {
    margin-bottom: 40px;
}

.range_txt {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}

.range_txt h4 {
    font-size: 14px;
    margin-bottom: 3px;
}

.range_txt h4 span {
    font-size: 18px;
}

.range_txt div {
    font-size: 16px;
    display: none;
}

.range_txt div.active {
    display: block;
}

.range_bar_wrap {
    position: relative;
    background: #e9e9e9;
    height: 3px;
    margin-bottom: 20px;
    /* 디자인상 없앰 */
    display: none;
}

.range_bar_wrap .range_bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 3px;
    width: 16.7%;
    transition: 0.3s;
}

.register_slide {
    width: 100%;
    /* display: flex; */
}

.register_step {
    flex: 1;
}

.register_step ul {
    padding: 0;
}

.register_step li {
    /* margin-bottom: 24px; */
}



.register_step input {
    margin-bottom: 6px;
    width: 100%;
    padding: 15px 14px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #C1C1C1;
    /* box-shadow: 3px 3px 6px #9898981A; */
    min-height: 45px;
    max-height: 45px;
}
#joriwon_text{
    background: #F9F9F9;
    color:#C1C1C1;
    font-weight: 500;
}

.register_step input::placeholder {
    color: #989898;
    font-size: 14px;
}

.register_step select {
    margin-bottom: 6px;
    width: 100%;
    padding: 14px 10px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #C1C1C1;
    /* box-shadow: 3px 3px 6px #9898981A; */
    background: #fff;
}

.register_step select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
}

button.step_finish {
    width: 100%;
    line-height: 1;
    padding: 13px 0;
    border-radius: 23px;
    font-size: 17px;
    box-shadow: 3px 3px 6px #9898981a;
    color: #fff;
    margin-top: 0;
    border: none;
    background-color: #32D1D4;
}

/* .step1 button.step_move {background: #fff; width: calc(50% - 4px); line-height: 1; padding: 13px 0; border-radius: 8px; font-size: 14px; box-shadow: 3px 3px 6px #9898981a; margin-top: 25px; position: relative;} */

.adr_search_wrap {
    position: relative;
}

.adr_search_btn {
    color: #fff;
    border: none;
    font-size: 14px;
    border-radius: 6px;
    padding: 6px 20px;
    position: absolute;
    right: 8px;
    top: 8px;
}

/* 221208 nhjo step1 + step2 로 인한 추가 */
.step1 .adr_search_wrap {
    position: relative;
}

.step1 .adr_search_btn {
    color: #fff;
    border: none;
    font-size: 14px;
    border-radius: 6px;
    padding: 6px 20px;
    position: absolute;
    right: 8px;
    top: 8px;
}

.step3 .state1_wrap,
.step3 .state2_wrap,
.step3 .state3_wrap {
    display: none;
}

.double_ck {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.double_ck input {
    display: none;
}

.double_ck input+label {
    height: 24px;
    /*  */
    background-size: contain;
    margin: 0;
    padding-left: 32px;
}

.double_ck input:checked+label {
    /*  */
    background-size: contain;
}
.double_ck label span {
    font-size: 13px;
}

.rgs_evt_tit {
    font-size: 25px;
    text-align: center;
}

.rgs_evt_tit span {
    position: relative;
}

.rgs_evt_tit span::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 60%;
    bottom: 2px;
    background: #ffeac2;
    left: 0;
}

.rgs_evt_tit span i {
    position: relative;
    font-style: normal;
}

.rgs_evt_sub_tit {
    text-align: center;
    font-size: 18px;
    margin: 15px 0 20px;
    font-weight: bold;
}

.rgs_evt_sub_tit span {
    display: inline-block;
    padding: 7.5px 10px;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
}

.rgs_evt_img {
    margin: 0 !important;
}

.rgs_evt_txt {
    color: #C70404;
    font-size: 14px;
    padding-top: 10px;
    margin-bottom: 30px;
    text-align: center;
}

.rgs_evt_txt img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.rgs_evt_txt p {
    display: flex;
    justify-content: center;
    align-items: center;
}

/**** 221114 nhjo 이벤트 수정 */
.rgs_evt_txt_re {
    display: flex;
    align-items: center;
    color: #FF456B;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
}

.rgs_evt_txt_re img {
    padding-left: 5px;
    width: 20px;
    height: 15px;
    margin-right: 5px;
}
/*공통 css*/
/* .bi {
    font-family: 'Binggrae2-Bold', sans-serif;
}
.bi2 {
    font-family: 'Binggrae2', sans-serif;
}
.fe {
    font-family: 'Fegan', sans-serif;
}
.th {
    font-family: 'Thedog', sans-serif;
}
.ct {
    font-family: 'KOTRAHOPE', sans-serif;
} */
.ls15 {
    letter-spacing: -15px;
}
.m36 {
    margin: 0 36px;
}
.m18 {
    margin: 0 18px;
}
.p18 {
    padding: 0 18px;
}
.ls0 {
    letter-spacing: 0;
}

/* 팝업 공통 css */
.black_bg {
    position: fixed;
    background: rgba(0,0,0,.5);
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    width: 100%;
    height: 100%;
}
.black_bg2 {
    position: fixed;
    background: rgba(0,0,0,.5);
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    width: 100%;
    height: 100%;
}
.popup {
    position: fixed;
    text-align: center;
    box-sizing: border-box;
    width: 90.4%;
    z-index: 999;
    display: none;
    background: #fff;
    border-radius: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    padding: 40px 22px;
}
.popup .pop_close {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 21px;
    right: 21px;
}
.popup .pop_close img {
    width: 100%;
}
.hidden {
    overflow: hidden !important;
    touch-action: none;
}

.black_bg3 {
    position: fixed;
    background: rgba(0,0,0,.5);
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
}

body,
html {
    letter-spacing: -0.2px;
    font-size: 14px;
    color: #343434;
}
input,
select,
textarea {
    font-size: 14px;
}
/* 헤더밑으로 내려간 wrapper */
#register_wrapper {
    /* iOS 11.2 이상 */
    padding-top: calc(0px + env(safe-area-inset-top));
    /* padding-bottom: calc(80px + env(safe-area-inset-bottom)); */
    /* iOS 11.0 버전 */
    padding-top: calc(48px + constant(safe-area-inset-top));
    /* padding-bottom: calc(80px + constant(safe-area-inset-bottom)); */
    box-sizing: border-box;
    position: relative;
    /* min-height: 100vh; */
}

#register_wrapper .kcp_wrap {
    /* height:100vh;
    position: relative; */
}
/*-------- 헤더공통 --------*/
#header {
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    color: #fff;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    padding: 12px 18px;
    padding: calc(12px + env(safe-area-inset-top)) 18px 12px 18px;
    padding: calc(12px + constant(safe-area-inset-top)) 18px 12px 18px;
}
#header .head_left {
    display: flex;
    align-items: center;
    white-space: wrap;
    width: 67%;
}
#header .head_left img {
    width: 12px;
}
#header .head_left span {
    margin-left: 8px;
}
/* #header .head_right {display: flex;align-items: center;width: 180px;justify-content: space-around;} */
#header .head_right {
    display: flex;
    align-items: center;
    width: 180px;
    justify-content: flex-end;
    gap: 10px;
}

/* 헤더-등록버튼 */
#header .head_right .enroll {
    color: #FFE57E;
    font-size: 16px;
}

/* 랭킹헤드 - 검색.위치.알림 */
.rank_head .head_right span {
    width: 24px;
    height: 24px;
    display: flex;
    position: relative;
}
.rank_head .head_right span img {
    width: 100%;
    height: 100%;
}
.rank_head .head_right span.on::after {
    content: '';
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    background: #FFDB4B;
    border-radius: 100%;
    right: 0;
}

.rank_info_head .head_right span {
    width: 24px;
    display: flex;
    margin-left: 18px;
    position: relative;
}
.rank_info_head .head_right span img {
    width: 100%;
}
.rank_info_head .head_right span.on::after {
    content: '';
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    background: #FFDB4B;
    border-radius: 100%;
    right: 0;
}

/* 공통헤드 - 검색.위치.알림.장바구니 */
.common_head .head_right span {
    width: 24px;
    height: 24px;
    display: flex;
    position: relative;
}
.common_head .head_right span img {
    width: 100%;
}
.common_head .head_right span.on::after {
    content: '';
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    background: #FFDB4B;
    border-radius: 100%;
    right: 0;
}

/* radius 헤드 */
.radius_head {
    border-radius: 0 0 0 36px;
}

/* 백아이콘 헤드 */
#header.back_head {
    padding: 12px 18px 12px 12px;
    padding: calc(12px + env(safe-area-inset-top)) 12px 12px 12px;
    padding: calc(12px + constant(safe-area-inset-top)) 12px 12px 12px;
}

/* 이미지 필터 */
.img_wrap {
    position: relative;
}
.filter::after {
    content: '';
    position: absolute;
    display: block;
    background: #F8CD3F;
    opacity: 0.1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

/* 탭메뉴 공통 */
.common_tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}
.tab_menu {
    display: flex;
}
.tab_menu li {
    margin-right: 24px;
}
.tab_menu li.on {
    color: #67CCBF;
    position: relative;
    font-weight: 800;
}
.tab_menu li.on::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: #67CCBF;
    bottom: -6px;
}
.tab_box {
    display: none;
}
.tab_box.on {
    display: block;
}
.common_write {
    padding: 8px 13px 7px;
    border-radius: 6px;
    box-shadow: 3px 3px 6px #9898981a;
}
.common_write span {
    font-size: 14px;
    color: #fff;
}

.common_tab2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}
.tab_menu2 {
    display: flex;
}
.tab_menu2 li {
    margin-right: 24px;
}
.tab_menu2 li.on {
    color: #67CCBF;
    position: relative;
    font-weight: 800;
}
.tab_menu2 li.on::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: #67CCBF;
    bottom: -6px;
}
.tab_box2 {
    display: none;
}
.tab_box2.on {
    display: block;
}
.common_write2 {
    padding: 8px 13px 7px;
    border-radius: 6px;
    box-shadow: 3px 3px 6px #9898981a;
}
.common_write2 span {
    font-size: 14px;
    color: #fff;
}

/* 등록버튼 */
.enroll_post {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 13px 0;
    text-align: center;
    margin: 4px 0 24px;
    box-shadow: 3px 3px 6px #9898981A;
}
.enroll_comment {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 13px 0;
    text-align: center;
    margin: 4px 0 24px;
    box-shadow: 3px 3px 6px #9898981A;
}

/* 글쓰기 */
.write_btn {
    display: inline-block;
    padding: 11px;
    width: 49px;
    border-radius: 100%;
    box-sizing: border-box;
    box-shadow: 3px 3px 6px #98989833;
    position: fixed;
    right: 14px;
    bottom: 87px;
}
.write_btn img {
    width: 100%;
}

/* 목록버튼 */
.list-go {
    padding: 8px 20px;
    border-radius: 6px;
    box-shadow: 3px 3px 6px #9898981A;
    font-size: 14px;
}

/* 게시글공통 */
#post_top {
    padding: 15px 18px 12px;
}
#post_top h3 {
    font-size: 18px;
}
#post_top .post_info_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
}
#post_top .post_info {
    display: flex;
    align-items: center;
}
#post_top .post_info .name {
    padding-right: 10.5px;
}
#post_top .post_info .date {
    color: #C5C5C5;
    padding-right: 10.5px;
}
#post_top .post_info .like {
    display: flex;
    align-items: center;
    padding-right: 10.5px;
}
#post_top .post_info .like span {
    width: 14px;
}
#post_top .post_info .review {
    display: flex;
    align-items: center;
}
#post_top .post_info .review span {
    width: 14px;
}
#post_top .post_btn {}
#post_top .post_btn button {
    padding: 0;
    border: none;
    background: transparent;
    width: 24px;
}
#bo_v #post_cont_wrap .post_cont a {
    color: blue !important;
}
#post_cont_wrap {}
#post_cont_wrap .post_cont {
    font-size: 15px;
    padding: 16px 18px 12px;
    line-height: 20px;
    box-sizing: border-box;
    word-wrap: break-word;
}
/* #post_cont_wrap .post_cont img {width: 100% !important; height: auto !important; display: block;} */
#post_cont_wrap .post_cont img {
    width: 100% !important;
    height: auto !important;
    display: inline !important;
    vertical-align: bottom;
}
#post_cont_wrap #bo_v_img img {
    margin-bottom: 0;
    width: 100%;
}
#post_cont_wrap .post_bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    position: relative;
}
#post_cont_wrap #bo_v_share {
    padding: 0;
}
#post_cont_wrap #bo_v_share a {
    display: block;
    width: 24px;
}
#post_cont_wrap #bo_v_act {
    margin-bottom: 0;
}
#post_cont_wrap #bo_v_act .bo_v_good {
    border: none;
    width: auto;
    line-height: 1;
}
#post_cont_wrap #bo_v_act a {
    margin-right: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #DBDBDB;
}
#post_cont_wrap #bo_v_act a:hover {
    color: #FEAF13;
}
#post_cont_wrap #bo_v_act a .icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 5px;
}
#post_cont_wrap #bo_v_act a .img_wrap {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 5px;
}

.bo_vc_w textarea.autosize {
    min-height: 60px;
}
.bo_vc_w_info {
    margin: 5px 0 !important;
}

.cm_wrap header {
    display: flex;
    align-items: center;
}
.cm_wrap header .pf_img img {
    width: 25px !important;
    height: 25px !important;
    object-fit: cover;
}
.cm_wrap header span {
    margin-right: 2px;
    margin-top: -2px;
}
.comment_wrap {
    min-height: 70vh;
}

.list_btn {
    border: 1px solid #DBDBDB;
    padding: 6px 16px;
    border-radius: 5px;
    color: #989898;
    font-size: 14px;
    box-shadow: 3px 3px 6px #9898981a;
    position: absolute;
    right: 18px;
    bottom: 16px;
}
.list_scrap {
    position: relative;
    bottom: auto;
    right: auto;
}

/*------페이징-------*/
.pg_wrap {
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
}
.pg_wrap .pg {
    display: flex;
    justify-content: center;
}
.pg_wrap .pg .pg_current {
    color: #fff;
    background: #67CCBF;
}
.pg_wrap .pg .sound_only {
    display: none;
}
.pg_wrap .pg .pg_page {
    margin: 0 2px;
}
.pg_current,
.pg_page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    background: #EDEDED;
    line-height: 26px;
    border-radius: 7px;
    font-size: 14px;
    color: #989898;
    margin: 0 4px;
}

/*------검색창 safe-area------*/
.bo_sch_wrap .bo_sch {
    padding-top: 13px !important;
    padding-top: calc(13px + env(safe-area-inset-top)) !important;
}

/*------브랜드컬러------*/
.ccl-1 {
    color: #67CCBF;
}
.ccl-2 {
    color: #FEAF13;
}
.ccl-3 {
    color: #FFDB4B;
}

.ccl-bg1 {
    background-color: #67CCBF;
}
.ccl-bg2 {
    background-color: #FEAF13;
}
.ccl-bg3 {
    background-color: #FFDB4B;
}

.ccl-bo1 {
    border: 1px solid #67CCBF;
}
.ccl-bo2 {
    border: 1px solid #FEAF13;
}
.ccl-bo3 {
    border: 1px solid #FFDB4B;
}

/*------서브컬러------*/
.ccl-4 {
    color: #A0B9B7;
}
.ccl-5 {
    color: #67CCBF;
}

.ccl-bg4 {
    background-color: #A0B9B7;
}
.ccl-bg5 {
    background-color: #88D6CC;
}
.ccl-bg6 {
    background-color: #117A4e;
}
.ccl-bg7 {
    background-color: #f17c04;
}

/*------배경색------*/
.cbg-1 {
    background: #fff;
}
.cbg-2 {
    background: #F9F9F8;
}
.cbg-3 {
    background: #DBDBDB;
}
.cbg-4 {
    background: #EDEDED;
}

/*------border------*/
.cb-1 {
    border: 1px solid #C1C1C1;
}
.cb-2 {
    border: 1px solid #DBDBDB;
}

/*------text-color------*/
.ctcl-1 {
    color: #343434;
}
.ctcl-2 {
    color: #989898;
}
.ctcl-3 {
    color: #C5C5C5;
}
.ctcl-4 {
    color: #DBDBDB;
}

/*------button------*/
button:focus,
input:focus,
option:focus,
select:focus,
textarea:focus {
    outline: none;
}

/*------Datepicker ui css 변경------*/
.ui-widget-header {
    border: 0 solid #dddddd !important;
    background: #fff !important;
}

.ui-datepicker-calendar > thead > tr > th {
    font-size: 14px !important;
}

.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: 8px 0;
}

.ui-button,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
html .ui-button.ui-state-disabled:active,
html .ui-button.ui-state-disabled:hover {
    border: 0 solid #c5c5c5 !important;
    background-color: transparent !important;
    font-weight: normal;
    color: #454545;
    text-align: center;
}

.ui-datepicker .ui-datepicker-title {
    margin: 0;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    padding: 0;
    font-weight: 800;
    font-family: 'Pretendard';
}

.ui-datepicker .ui-datepicker-title select {
    width: 30%;
    margin: 0;
    line-height: 14px;
    text-align: right;
    font-size: 14px;
    padding: 0;
    font-weight: 800;
    border: none;
}

.ui-datepicker {
    display: none;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    padding: 20px 20px 10px;
    width: 55%;
    box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.1);
}

.ui-widget.ui-widget-content {
    border: 1px solid #eee;
}

#datepicker:focus > .ui-datepicker {
    display: block;
}

.ui-datepicker-next,
.ui-datepicker-prev {
    cursor: pointer;
}

.ui-datepicker-next {
    float: right;
}

.ui-state-disabled {
    cursor: auto;
    color: hsla(0, 0%, 80%, 1);
}

.ui-datepicker-title {
    text-align: center;
    padding: 10px;
    font-weight: 100;
    font-size: 20px;
}

.ui-datepicker-calendar {
    width: 100%;
}

.ui-datepicker-calendar > thead > tr > th {
    padding: 10px 5px;
    font-size: 20px;
    font-weight: 400;
}

.ui-datepicker-calendar > tbody > tr > td > a {
    color: #000;
    font-size: 12px !important;
    font-weight: bold !important;
    text-decoration: none;
}

.ui-datepicker-calendar > tbody > tr > .ui-state-disabled:hover {
    cursor: auto;
    background-color: #fff;
}

.ui-datepicker-calendar > tbody > tr > td {
    border-radius: 100%;
    width: 44px;
    height: 25px;
    cursor: pointer;
    padding: 5px;
    font-weight: 100;
    text-align: center;
    font-size: 12px;
}

.ui-datepicker-calendar > tbody > tr > td:hover {
    background-color: transparent;
    opacity: 0.6;
}

.ui-button:focus,
.ui-button:hover,
.ui-state-focus,
.ui-state-hover,
.ui-widget-content .ui-state-focus,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-focus,
.ui-widget-header .ui-state-hover {
    border: 0 solid #cccccc;
    background-color: transparent;
    font-weight: normal;
    color: #2b2b2b;
}

.ui-widget-header .ui-icon {
    /*  */
}

.ui-icon-circle-triangle-e {
    background-position: -20px 0 !important;
    background-size: 36px;
}

.ui-icon-circle-triangle-w {
    background-position: -0px -0px !important;
    background-size: 36px;
}

.ui-datepicker-calendar > tbody > tr > td:first-child a {
    color: red !important;
}

.ui-datepicker-calendar > tbody > tr > td:last-child a {
    color: #0099ff !important;
}

.ui-datepicker-calendar > thead > tr > th:first-child {
    color: red !important;
}

.ui-datepicker-calendar > thead > tr > th:last-child {
    color: #0099ff !important;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    border: 0;
    background: #f1f1f1;
    border-radius: 50%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.inp {
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 4px;
    border: 0;
}

.inp:focus {
    outline: none;
    background-color: #eee;
}

img.ui-datepicker-trigger {
    display: none;
}

.layer_bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    display: none;
    z-index: 999;
}

.alert-confirm {
    position: fixed;
    top: 50%;
    left: 10%;
    width: 80%;
    z-index: 9999;
    border-radius: 16px;
    background: #fff;
    transform: translate(0, -50%);
    overflow: hidden;
}

.alert-confirm .alert-title {
    background-color: #67CCBF;
    color: #fff;
    padding: 12px 18px;
    box-sizing: border-box;
}

.alert-confirm .alert-message {
    padding: 20px;
}
.alert-confirm .alert-btn-wrapper {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
}
.alert-confirm .alert-btn-wrapper .okbtn {
    background-color: #67CCBF;
    padding: 6px 10px;
    border-radius: 4px;
    color: #fff;
}
.alert-confirm .alert-btn-wrapper .closebtn {
    background-color: #b3b3b3;
    padding: 6px 10px;
    border-radius: 4px;
    color: #fff;
    margin-left: 10px;
}

/**** */
.step5-1 h2,
.step5-2 h2,
.step5-3 h2 {
    font-size: 16px;
    margin-bottom: 8px;
}

.step5-1 > p,
.step5-2 > p,
.step5-3 > p {
    font-size: 14px;
    color: #FF0000;
}

.step5-1 .img_wrap,
.step5-2 .img_wrap,
.step5-3 .img_wrap {
    margin: 18px 0 20px;

}

.ck_tit {
    font-size: 17px;
    /* padding-bottom: 20px; */
    font-weight: 800;
}

/**** 221114 nhjo 이벤트 수정 */
.ck_tit_re {
    font-size: 17px;
    font-weight: 800;
    display: flex;
    align-items: center;
}

/****  */
.ck_row {
    display: flex;
    flex-wrap: wrap;
    margin-top:20px;
    justify-content: flex-start;
}

.ck_col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    margin-bottom: 22px;
}

.ck_col input {
    display: none;
}

.ck_col input+label {
    height: 24px;
    background: url(../../img/register/check_off.png) no-repeat;
    background-size: contain;
    padding-left: 32px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.ck_col input:checked+label {
    background: url(../../img/register/check_on.png) no-repeat;
    background-size: contain;
    display: block;
}

.ck_col label span {
    font-weight: 800;
    font-size: 17px;
    text-align: left;
}
.ck_col2 label span {
    font-weight: 600;
    font-size: 15px;
    text-align: left;
}
.ck_col label[for="ac_0"]{
    width:100%;
}
.ck_col img {
    width: 100%;
    margin-top: 13px;
}
.gift_info_alert{
    margin-top:10px; 
    margin-bottom:20px;
    display: flex;
    align-items: center;
    color: #FF456B;
    font-size: 13px;
    text-align: center;
    justify-content: center;
}
.gift_info_alert img {
    padding-left: 5px;
    width: 20px !important;
    margin-right: 5px;
    margin-top:0 !important;
    margin-left:10px !important;
}
/**/
.ck_gift input {
    display: none;
}

.ck_gift label {
    display: inline-block;
    margin-bottom: 6px;
    height: 151px;
}

.ck_gift label img {
    width: 100%;
}

.ck_gift input+label {
    position: relative;
}

.ck_gift input+label img {
    position: relative;
}

.ck_gift input+label::after {
    content: '';
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    /*  */
    left: 10px;
    top: 10px;
    background-size: contain;
}

.ck_gift input:checked+label::after {
    content: '';
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    /*  */
    left: 10px;
    top: 10px;
    background-size: contain;
}

.gift_col {
    width: calc(100% - 6px);
    margin-bottom: 10px;
}


.gift_col_flex {
    display: flex;
    gap: 0px;
    justify-content: space-between;
}
.gift_col label {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.gift_col input {
    display: none;
}

.gift_col .cont {
    text-align: center;
    font-size: 12px;
}
.ck_row .tit,
.gift_col .tit {
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
}
.step5_1 .ck_row .tit{
    margin-bottom: 0px;
}
.gift_col .gift_img {
    width: 100%;
    display: inline-block;
}

.gift_col .gift_img img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.gift_col .check_img {
    }

.gift_col input[type='radio']+.check_img {
    width: 24px;
    height: 24px;
    background: url(../../img/register/check_off.png) no-repeat right;
    background-size: contain;
    display: inline-block;
    margin: 0 8px 8px 0;
}

.gift_col input[type='radio']:checked+.check_img {
    background: url(../../img/register/check_on.png) no-repeat right;
    background-size: contain;
}
.gift_col_renew{
    width: 47%;
}
.gift_col_renew .gift_col_flex{
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.step6 {
    position: relative;
}

.step6 h2 {
    font-size: 16px;
    margin-bottom: 8px;
}

.step6 textarea {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #C1C1C1;
    height: 80px;
    box-shadow: 3px 3px 6px #9898981A;
}

.step6 p {
    text-align: center;
    font-size: 12px;
    line-height: 24px;
    padding: 22px;
    box-sizing: border-box;
    border-top: 8px solid #F6F6F6;
    margin-top: 30px;
    width: 100%;
    margin-left: -18px;
    width: calc(100% + 36px);
}

.step6 p a {
    color: #67CCBF;
    text-decoration: underline;
}



.custom_popup {}

#fregister #fregister_private {
    background: #fff;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    border: 0;
    border-radius: 13px;
}

#fregister #fregister_private h2 {
    padding: 10px;
    text-align: center;
    margin-bottom: auto;
}

#fregister #fregister_private div,
#fregister_private textarea {
    height: 300px;
}

#fregister #fregister_private div,
#fregister_private div {}

#fregister .fregister_agree {
    position: relative;
    display: flex;
}

#fregister .fregister_agree label {
    display: flex;
    font-size: 14px;
    margin: auto;
    padding: 10px 0;
}

#fregister .fregister_agree label input {
    width: auto;
    margin: auto 5px auto auto;
}

.fregister_line {
    border: 1px solid #C1C1C1;
}

.pregnant h2 {
    font-size: 16px;
    margin-bottom: 8px;
}

.pregnant > p {
    font-size: 14px;
}

.pregnant ul {
    display: flex;
    justify-content: space-around;
    margin: 24px 0;
}

.pregnant ul.baby_sex_wrap {
    justify-content: center;
}

.pregnant ul li {
    margin-bottom: 0;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    width: calc((100% - 27px)/3);
    text-align: center;
    color: #C5C5C5;
    box-shadow: 3px 3px 6px #9898981A;
    padding: 14px 0;
}

.pregnant ul li label {
    margin-bottom: 0;
}

.pregnant ul li span {
    width: 48px;
    margin-bottom: 12px;
    display: inline-block;
}

.pregnant ul li span img {
    width: 100%;
}

.pregnant ul li p {
    line-height: 1;
    font-size: 14px;
}

.pregnant ul li input {
    display: none;
}

.pregnant ul li.on {
    border: 1px solid #67CCBF;
    color: #67CCBF;
}

/* .pregnant .state2_wrap, .pregnant .state3_wrap {display: none;} */

/* 회원가입 분류 */
.register_li {
    box-shadow: 3px 3px 6px #9898981a;
    border-radius: 16px;
    background-color: white;
    height: 90px;
    display: flex;
    z-index: 1000;
}

.register_a {
    display: flex;
    align-items: center;
    width: 100%;
}

.register_img {
    width: 48px;
    margin-left: 21px;
}

.register_img img {
    width: 100%;
}

.register_txt {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.register_txt_main {
    font-size: 22px;
}

.register_txt_sub {
    margin-top: 8px;
    color: #989898;
}

/* 회원가입 정보수정  */
#fregister_private table {
    flex: 1;
    text-align: center;
    width: 90%;
    border: 1px solid #000000;
    margin: auto;
}

#fregister_private table thead {}

#fregister_private table thead tr {
    border: 1px solid #000000;
}

#fregister_private table thead th {
    border: 1px solid #000000;
    width: 33.3%;
}

#fregister_private table tbody {}

#fregister_private table tbody tr {
    border: 1px solid #000000;
}

#fregister_private table tbody td {
    border: 1px solid #000000;
}

.will_visit_gynecology_box {}

.state2_wrap .txt,
.state3_wrap .txt,
.will_visit_gynecology_box .txt {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 25px;
    text-align: left;
}

.will_visit_gynecology_radio {
    display: flex;
}

.will_visit_gynecology_select {
    position: relative;
}

/* 230308 nhjo event renew */
.event1_box {
    width:100%;
}

.event1_title {}

.event1_title input {
    display: none;
}

.event1_title input+label {
    height: 24px;
    background: url(../../img/register/check_off.png) no-repeat right;
    background-size: contain;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    width: fit-content;
    padding-right: 32px;
    font-weight: 600;
}

.event1_title input:checked+label {
    background: url(../../img/register/check_on.png) no-repeat right;

    background-size: contain;
    color: #32D1D4;
}

.event1_title label span {
    font-size: 16px;
    color: #343434;
}

.ck_col img {
    width: 100%;
    margin-top: 13px;
}

.event1_small_title {
    margin-top: 9px;
    margin-bottom: 10px;
    color: #343434;
    font-size: 13px;
}

.event1_img_box {
    margin-bottom: 27px;
}

.event1_img_box img {
    width: 100%;
}

.half_img {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    margin-bottom: 15px;
}

.half_img img {
    width: 45%;
    box-sizing: border-box;
}

.event3_rgs_evt_txt_re {
    display: flex;
    align-items: center;
    color: #FF456B;
    font-size: 14px;
    text-align: center;
    justify-content: center;
    margin-top: 25px;
}
.event3_rgs_evt_txt_re img {
    padding-left: 5px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
/* 기존 css end */
/* 공통 레이아웃 */

.form_01 {
    max-width: 500px;
    margin: 0 auto;
}
.width_500 {
}
#hd_wrapper {
    display: none !important;
}
.step_move_wrap {
    position: fixed;
    z-index: 10;
    width: 90%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    justify-content: space-between;
    bottom: 10px;
}

/* .step_back,
.step_move {
    border-radius: 20px;
    padding: 11px 0;
    border: none;
    font-size: 15px;
    min-width: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    text-align: center;
} */
button.step_back,
button.step_move,
button.state1_btn_wrap,
button.state2_btn_wrap,
button.state3_btn_wrap{
    border-radius: 20px;
    padding: 11px 0;
    border: none;
    font-size: 15px;
    min-width: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    display: block;
    box-sizing: border-box;
    text-align: center;
    width:100%;
}

.step_back::before {
    display: inline-block;
    width: 40px;
    background-size: 40px 40px;
}

.step_move::after {
   
    display: inline-block;
    width: 40px;
    background-size: 40px 40px;
    filter:brightness(200%);
} 

.step_move,
button.step_move,
button.state1_btn_wrap,
button.state2_btn_wrap,
button.state3_btn_wrap{
    color: white;
    border:1px solid #32D1D4;
    background-color: #32D1D4;
    box-sizing: border-box;
}
.step_back,
button.step_back {
    color: #C5C5C5;
    background-color: white;
    border:1px solid #DBDBDB;
    box-sizing: border-box;
}
/*step3의 상태 선택 start*/
.datepicker {
    /*  */
    background-size: 15px 15px;
    background-position: right 15px center;
}
.datepicker_birth {
    /*  */
    background-size: 15px 15px;
    background-position: right 15px center;
}
.state_chk_wrap li {
    position: relative;
    background-color: #F9F9F9;
    border-radius: 10px;
    border: 3px solid transparent;
}
li.select_my_tit{
    font-size:20px;
    background:white;
    margin-bottom: 30px !important; 
    border:none;
}
.state_chk_wrap li:nth-child(1).on{
    border: 3px solid transparent;
}
.state_chk_wrap li.on {
    border: 3px solid #32D1D4;
    box-sizing: border-box;
}
.state_chk_wrap li.on .chk {
    display: block;
}
.state_chk_wrap input {
    display: none;
}
.chk {
    position: absolute;
    top: 5px;
    right: 5px;
    display: none;
    width: 22px
}
.chk img {
    width: 100%;
    display: block;
}
label[for="state1"],
label[for="state2"],
label[for="state3"] {
    display: flex;
    justify-content: center;
    gap: 40px;
    align-items: center;
    padding: 18px 0;
    box-sizing: border-box;
    margin-bottom: 0;
}
.state1_wrap label,
.state2_wrap label,
.state3_wrap label {
    display: flex;
    justify-content: center;
    gap: 40px;
    align-items: center;
    padding: 18px 0;
    box-sizing: border-box;
}
.state_img {
    width: 49px;
}
.state_img img {
    width: 100%;
    display: block;
}
.s_desc {
    font-size: 15px;
    color: #C1C1C1;
    font-weight: 500;
    margin-bottom: 5px;
}
.b_desc {
    min-width: 150px;
    max-width: 150px;
    font-size: 18px;
    font-weight: 800;
}

.state_chk_wrap li {
    }
.state1_wrap .txt,
.state2_wrap .txt,
.state3_wrap .txt {
    font-size: 20px;
    margin-bottom:30px;
    font-weight: 800;
}
.state1_wrap {
    }
.state1_wrap select,
.state3_wrap select {
    width: 100%;
    font-size: 15px;
    padding-left: 20px;
    border-radius: 7px;
    border: 1px solid #C1C1C1;
}
.state1_wrap select:hover option,
.state3_wrap select:hover option {
    background-color: #32d1d43b;
}
/*step3의 상태 선택 end*/
/*state2*/
.state2_wrap {
    width: 100%;
}
.state2_wrap input {
    width: 100%;
    border: 1px solid #A7A7A7;
    border-radius: 8px;
    padding: 14px 10px;
    font-size: 15px;
    margin-bottom: 10px;
    box-sizing: border-box;
}
input[type='date']{
    min-height: 34px;
}
input[type='date'], input[type='time'] {
    -webkit-appearance: none;
}
.state2_wrap input.datepicker {
    color: #32D1D4;
    border: 1.5px solid #32D1D4;
    width:100%;
    background-color: white;
    white-space: nowrap;
}
.state2_wrap input.datepicker::placeholder {
    color: #32D1D4;
}
.state2_wrap input::placeholder {
    }
.ReactModalPortal select{
    min-height: 34px;
}
input[type="text"],
select,
input[type="password"] {
    border: 1px solid #C1C1C1;
    border-radius: 8px;
    padding: 13px 17px;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.adr_search_wrap {
    position: relative;
}
input[name="mb_zip"] {
    width: 100%;

}
button.btn_frmline {
    margin-bottom: 0 !important;
    height: auto !important;
    line-height: 18px !important;
    font-weight: normal !important;
    position: absolute !important;
    right: 8px;
    top: 42%;
    transform: translateY(-50%);
    background-color: #32D1D4;
    color: white;
    padding: 6px 20px !important;
    box-sizing: border-box;
    border: none;
    border-radius: 8px;
}
select[name="emaildomain"] {
    border: 1px solid #A7A7A7;
    border-radius: 22px;
    padding: 13px 17px;
    box-sizing: border-box;
    margin-bottom: 10px;
    max-width: 100%;
}
select[name="mb_4"] {
    /* height: auto;
    border: 1px solid #C1C1C1;
    border-radius: 8px;
    padding: 13px;
    box-sizing: border-box;
    margin-bottom: 10px; */
    border: 1px solid #C1C1C1;
    border-radius: 8px;
    padding: 13px 17px;
    box-sizing: border-box;
    /* margin-bottom: 50px; */
}

.register_form_inner {
    width: 100%;
}
#ui-datepicker-div {
    background-color: white;
    z-index: 20 !important;
    font-family: 'Pretendard' !important;
}
.form_01 select {
    position: relative;
    min-height: 45px;
    max-height: 45px;
    height: auto !important;
    max-width: 100%;
    color:#C1C1C1;
}
.form_01 select::after{
    content: "/img/down.svg";
    position: absolute;
    right:10px;
}
.img_wrap img {
    width: 100%;
}
.step_back_btn {
    border-radius: 20px;
    padding: 11px 0;
    border: none;
    font-size: 15px;
    min-width: 45%;
    color: white;
    background-color: #32D1D4;
    text-align: center;
    cursor: pointer;
    width:90%;
    margin:0 auto;
}
.first_step_back{
    background-color: white;
    width:100%;
}
.state_title{
    font-size:20px; text-align:left; width:100%; margin:0 auto; margin-bottom:30px; font-weight:800; 
}
.event_main_title{
    font-size:20px; text-align:left; width:100%; margin:0 auto; margin-bottom:14px; font-weight:800; 
}
/* .state1_btn_wrap,
.state2_btn_wrap,
.state3_btn_wrap{
    border-radius: 20px;
    padding: 11px 0;
    border: none;
    font-size: 15px;
    min-width: 48%; 
    color: white;
    background-color: #32D1D4;
    text-align: center;
    cursor: pointer;
    margin:0 auto;
} */
.ck_row {
    gap: 6%;
}
.ck_col {
    width: 47%;
    /* padding: 10px; */
    box-sizing: border-box;
}
.event1_origin {
    box-sizing: border-box;
    /* box-shadow: 0 3px 6px #AEAEAE29; */
    border-radius: 0px;
}
.event1_origin .ck_col img{
    border:1px solid #C1C1C1;
    border-radius: 8px;
}
.event1_origin .ck_col{
    margin-bottom: 0;
}
.interest_card {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.interest_card .card_col {
    width: 33%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.card_col {
    width: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.card_col img {
    display: block;
}
/**/
.card_col {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: calc(100%/3);
}

.card_col input {
    display: none;
}

.card_col input+label {
    
    background-size: contain;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.card_col input:checked+label {
    
    background-size: contain;
    border: 4px solid #32D1D4;
    border-radius: 14px;
    position: relative;
}
.card_col input:checked+label::after {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 24px;
    height: 24px;
    text-align: center;
    vertical-align: middle;
    z-index: 2;
    background-color: #32D1D4;
    /*  */
    background-size: 24px 24px;
    display: inline-block;
    border-radius: 14px;
    color: white;
    box-sizing: border-box;

}
.card_col label span {
    font-size: 13px;
}

.card_col img {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

label[for="notice"] {
    background-color: #F7F7F7 !important;
    width: 100%;
    box-sizing: border-box;
}
/* 동의 창*/
.agree_wrap{
    position: fixed;
    top:0;
    left:0;
    background-color: rgba(0, 0, 0, 0.209);
    width:100%;
    height:100vh;
    z-index: 99;
}
.agree_chk_wrap {
    width: 100%;
    box-sizing:border-box;
    background: #fff;
    padding: 18px 18px 60px 18px;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    box-shadow: 0 -3px 6px #9E9E9E29;
    top: 50%;
    left: 0;
    position: fixed;
    height: 50vh;
    overflow: scroll;
    z-index: 999999;
}
.last_btn_wrap {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: nowrap;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    margin-top:20px;
    /* position: fixed; */
    /* bottom: 10px;
    left: 50%;
    transform: translateX(-50%); */
}
.step_back_6 {
    width: 50%;
}
#step_6_agree_chk {
    font-size: 15px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    width: 100%;
    border: 1px solid #32D1D4;
    color: #ffffff;
    background-color: #32D1D4;
}
/* step6 */
.register_done_tit{
   font-size: 20px; 
}
.step6 .register_title {
    margin-bottom: 10px;
    font-weight: 800;
}
.now_step{
    font-size: 20px;
    font-weight: 800;
    margin-bottom:10px;
    color:#32D1D4;
}

.w_90{
    width:90%;
    margin:20px auto;
}
.reg_btn02{
    /* background:red !important; */
    margin-bottom: 40px  !important;
}


.will_visit_gynecology_wrap{
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    color:#C1C1C1;
    justify-content: center;
}
.will_visit_gynecology_wrap > div label {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 3px 3px 15px #0000001A;
    padding:80px 30px 20px 30px; 
    border: 3px solid transparent;
    box-sizing: border-box;
    font-size: 20px;
    position: relative;
}
.will_visit_gynecology_wrap > div img{
    width:100%;
    display: block;
}
.will_visit_gynecology_wrap input[type="radio"]{
    display: none;
}
.will_visit_gynecology_wrap 
input[type="radio"]:checked+label{ 
    border: 3px solid #32D1D4;
    border-radius: 8px;
    box-sizing: border-box;
    color:#32D1D4;
    font-weight: 800;
 } 
 .will_visit_gynecology_wrap .chk{
    display: none;
    position: absolute;
    top:10px;
    right:10px;
    width:24px;
 }
 .will_visit_gynecology_wrap .chk img{
    width:100%;
    display: block;
 }
 .will_visit_gynecology_wrap 
 input[type="radio"]:checked+label .chk{
    display: block;
 }
/* 
 .step_back {
    position: absolute;
    border: 1px solid #A7A7A7;
    color: #A7A7A7;
    background-color: #fff;
    background-color: red;
} */

.step6{
    width:100%;
    margin:0 auto;
}
.step6 .step_move_wrap {
    width: 90% !important;
    left: 50% !important;
    bottom: 10px;
    z-index: 23 !important;
    transform: translateX(-50%);
    position: fixed !important;
}
@media screen and (min-width:1000px) {
    .Member{
    }
    .step6 .step_move_wrap {
        width: 90% !important;
        left: 50% !important;
        bottom: 10px;
        z-index: 1 !important;
        transform: translateX(-50%);
        position: relative !important;
    } 
}
/* .state6{
    width:100%;
} */

.joriwon_event{
    margin-bottom:27px;
}

.joriwon_event .tit{
    font-size: 17px;
    margin-bottom: 14px;
    font-weight: 800;
}
.joriwon_main_wrap{
    margin-bottom: 50px;
}
.joriwon_select{

}
.joriwon_select .small_tit{
    font-size: 11px;
    color: #c1c1c1;
    margin-bottom: 14px;
    font-weight: 600;
    vertical-align: middle;
}
@media screen and (max-width:340px) {
    .joriwon_select .small_tit{
        display: block;
        margin-top: 4px;
    }
}
.joriwon_event textarea{
    resize: none;
    width:100%;
    height: 188px !important;
    border-radius: 8px;
    padding:10px;
    box-sizing: border-box;
}
.joriwon_select input{
    box-shadow:none;
}

.tea_bohum_wraps{
    display: flex;

}
.joriwon_select{
}
.joriwon_select ul{
    display: flex;
    flex-direction: wrap;
}
.joriwon_select ul li{
    width:47%;
    text-align: center;
}

.jorwon_gift_wraps{
}
.jorwon_gift_wraps ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.jorwon_gift_wraps ul li{
    width:47%;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    border:3px solid transparent;
    border-radius: 8px;
}
.jorwon_gift_wraps ul li input{
    display: none;
}
.jorwon_gift_wraps ul li .jori_select_box input[type="radio"] + label .checkbox_img {
    height: 20px;
    /*  */
    background-size: contain;
    padding-left: 24px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 13px;
}

.jorwon_gift_wraps ul li .jori_select_box input[type="radio"]:checked + label .checkbox_img {
    height: 20px;
    /*  */
    background-size: contain;
    padding-left: 24px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 13px;
}
.hope_joriwon_wrap input{
    display: none;
}
.hope_joriwon_wrap input[type="radio"] + label .checkbox_img,
.recommend_joriwon input[type="radio"] + label .checkbox_img {
    height: 20px;
    /*  */
    background-size: contain;
    padding-left: 24px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 13px;
}

.hope_joriwon_wrap input[type="radio"]:checked + label .checkbox_img,
.recommend_joriwon input[type="radio"]:checked + label .checkbox_img {
    height: 20px;
    /*  */
    background-size: contain;
    padding-left: 24px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 13px;
}


.jori_gift_img{
    width:80%;
    margin:0 auto;
    margin-bottom: 10px;
}
.jori_gift_img img{
    width:100%;
}
.jori_info_wrap{
    display: flex;
    justify-content: flex-start;
    /* gap:5px; */
    flex-wrap: nowrap;
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
}
label[for="jori5"] .jori_gift_img{
    border-radius: 8px;
    border: 1.7px solid #cacaca;
    overflow: hidden;
}
.joriwon_content{
    margin-bottom: 80px !important;
}
.joriwon_content::placeholder{
    color:#C1C1C1;
}

.hope_joriwon_wrap{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap:10%;
    /* margin-bottom: 10px;; */
}
.search_joriwon,
.recommend_joriwon{
    display: flex;
    gap: 4px;
    align-items: center;
}

.search_joriwon img ,
.recommend_joriwon img{
    display: none;
    width: 30px;
}
.where_luvmom_know{
    position: relative;
    margin-bottom:50px;
}
.where_luvmom_know img.down_btn{
    position: absolute;
    top: 15px;
    right: 10px;
}

.hope_joriwon_time_select{
    position: relative;
}
.hope_joriwon_time_select img.down_btn{
    position: absolute;
    top: 15px;
    right: 10px;
}
.required{

}
.required_term_chk{
    font-size: 17px;
}
.required_term span{
    width: 16px !important;
    font-size: 15px !important;
}
.selected_term_chk{
    font-size: 13px;
    color: #989898 !important;
    font-weight: 600 !important;
}
.selected_term_chk span{
    width: 9px !important;
    font-size: 13px !important;
}
.register_flex_wrap{
    display: flex;
    align-items: center;
    gap: 10px;
}
.Member input[type="text"], select, input[type="password"] {
    width:100%;
    border: 1px solid #C1C1C1;
    border-radius: 8px;
    padding: 13px 17px;
    box-sizing: border-box;
    margin-bottom: 10px;
    box-sizing: border-box;
   
}
.ReactModal__Overlay{
    z-index: 2000000000000 !important
}
.ReactModal__Content{
    z-index: 2000000000000 !important;
    box-sizing: border-box;
    top:0 !important;
    left:0 !important;
    width:100%;
    height:100vh;
    border:none !important;
    border-radius: 0 !important;
    background-color: red;
  }
  .hospital_list_table_wrap{
    max-height: 80vh;
    overflow-y: scroll;
  }
  .hospital_list_table {
    width:100%;
    font-size: 12px;
  }
  .hospital_list_table thead th{
    background-color: #dcdcdc;
    padding:10px 0;
    font-weight: 800;
  }
  .hospital_list_table thead th:nth-child(1){
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .hospital_list_table thead th:nth-child(3){
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .hospital_list_table td{
    padding:5px 3px;
    box-sizing: border-box;
    text-align: center;
  }


  /* 모달 스타일 */
.modal {
    position: fixed;
    z-index: 999999999999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: 3% auto;
    padding: 20px;
    border: 1px solid #888;
    /* width: 90%; */
    height:90vh;
    max-width: 800px;
    overflow-y: scroll;
    text-align: center;
    position: relative;
    box-sizing: border-box;
  }
  .modal-content2 {
    position: relative;
    background-color: #fefefe;
    margin: 3% auto;
    max-height:90vh;
    padding: 20px;
    border: 1px solid #888;
    overflow-y: scroll;
    text-align: center;
    position: relative;
    box-sizing: border-box;
  }
  .modal-content_mini {
    position: relative;
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width:300px;
    /* width: 90%;
    height:90vh; */
    text-align: center;
    position: relative;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .modal-content_mini p{
    font-weight: 800;
    color:red;
    margin-bottom: 20px;
  }
  .modal-content_mini .delete_btn{
    margin-left: 10px;
  }
  .close {
    display: inline-block;
    position: sticky;
    top:0px;
    right:10px;
    padding: 10px;
    cursor: pointer;
    font-size: 30px;
    width:100%;
    text-align: right;
  }
  
  .Member .tit{
    font-size:20px; text-align:left; width:100%; margin:0 auto; margin-bottom:30px; font-weight:800; 
  }
  .WhereSelect{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .WhereSelect .WhereSelect_inner{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    width:90%;
    height:70vh;
    max-width: 1000px;
  }
  .WhereSelect .WhereSelect_inner > div{
    width:48%;
    border:3px solid #dcdcdc;
    height:300px;
    padding:20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 800;
    text-align: center;
    box-sizing: border-box;
    gap:10px;
    background-color: white;
    transition: all 0.3s;
    cursor: pointer;
    
  }
  .WhereSelect .WhereSelect_inner > div:hover{
    opacity: 0.8;
  }
  .WhereSelect .WhereSelect_inner > div:nth-child(1){
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b2e1ff+0,66b6fc+100;Blue+3D+%236 */
background: linear-gradient(135deg,  #b2e1ff 0%,#66b6fc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  }
  .WhereSelect .WhereSelect_inner > div:nth-child(2){
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f9c667+0,f79621+100;Orange+3D+%234 */
background: linear-gradient(135deg,  #f9c667 0%,#f79621 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  }

  .selected{
    border:3px solid #32D1D4 !important;
  }
  .where_img{
    height:20px;
  }
  .WhereSelect_inner img{
    width:100%;
    display: block;
  }
  .WhereSelect_inner > div:nth-child(1) img{
  }

  .WhereSelect_inner > div:nth-child(2) img{
  }
  .seoulImg {
    background-color: rgba(128, 128, 128, 0.442);
    border-radius: 4px;
  }

  .main_img{
    width:100%;
  }
  .main_img img{
    width:100%;
    display: block;
  }
  .flex_city_select{
    display: flex;
    gap:10px;
  }

  @media screen and (max-width: 700px) {
    .WhereSelect_inner{
       flex-direction: column;
    }
    .WhereSelect .WhereSelect_inner > div{
        width: 90%;
        height : auto;
        padding:50px 0;
    }
  }

  .last_btn_wrap{
    width:100%;
    position: relative;
    box-sizing: border-box;
}
  @media screen and (min-width: 1000px) {
    .step_move_wrap{
    position: relative !important;
    bottom:0;
    width:100%;
    margin-top:30px;
    }
    .last_btn_wrap{
        width:100%;
        margin:0 auto;
    }
    
    
}

.applyDone_wrap{
    width:100%;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
}
.applyDone_wrap img{
    width:100%;
    margin-bottom: 10px;
}
.regist_tit{
    font-size: 15px;
    margin-bottom: 6px;
    font-weight: 800;
}
.register_wrap{
    margin-bottom: 20px;
}
.register_button{
    width:100%;
    padding:10px 0;
    box-sizing: border-box;
}
.flex{
    display: flex;
    align-items: center;
    gap:10px;
}
.select_box{
    display: flex;
    width:100%;
    gap:10px;
    justify-content: center;
}
.select_box input{
    display: none;
}
.select_box .select_box_item{
    display: block;
    width:50%;
    text-align: center;
    padding:10px 0;
    box-sizing: border-box;
    border-radius: 4px;
}
.select_box_item input + label{
    display: block;
    width:100%;
    border:2px solid #dcdcdc;
    text-align: center;
    padding:10px 0;
    box-sizing: border-box;
    border-radius: 4px;
    transition: all 0.4s;
  }

  .select_box_item input:checked + label{
    border: 2px solid #01C2D1;
  }

  .example_img{
    margin-top: 10px;
    margin-bottom: 10px;
  }
 .example_img .flex{
    align-items: flex-start;
    font-size:15px;
    font-weight:800;
    margin-top:20px;
 }
  .example_img img{
    width:100%;
    display: block;
  }
  .required{
    color:red;
  }
  .background_point{
    background-color: rgb(192, 248, 255);
  }
  .background_point2{
    background-color: rgb(255, 223, 228);
  }
  @media screen and (max-width:500px) {
    .select_box_item input + label{
        font-size: 12px;
        white-space: nowrap;
    }
  }