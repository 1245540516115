
@font-face {
  font-family: 'Pretendard';
  src: url('/src/static/font/pretendard/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@import url("https://cdnjs.cloudflare.com/ajax/libs/pretendard/1.3.9/static/pretendard.css");
html {

  -ms-content-zooming: none;
  -ms-touch-action: pan-x pan-y;

  }
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: 'Pretendard' !important;
}

a {
  color: #333;
  text-decoration: none !important;
}

textarea {
  font-family: 'Pretendard' !important;
}

button {
  font-family: 'Pretendard';
  cursor: pointer;
  background-color: #01C2D1;
  color:white;
  border:none;
  border-radius: 4px;
  padding:10px 20px;
  box-sizing: border-box;
}
.pc{
  display: block;
}
.mobile{
  display: none;
}
/* auto complete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-size: 12px !important;
  font-family: 'Pretendard' !important;
}
input:focus{
  border-color: #01C2D1;
  border:2px solid #01C2D1
}
ol,
ul {
    list-style: none;
}


blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
table.hover_highlight tbody tr:hover {
  background-color: #DFEBF6;
  transition: all 0.3s;
}
/* select 기본 */
select,button,input {
  font-family: 'Pretendard';
}

.detail_cont_wrap{
  display: flex;
  align-items: flex-start;
  gap:0px;
}
.detail_cont_wrap .MiniNavi{
  min-width: 280px;
  padding:20px;
  box-sizing: border-box;
}
.MiniTitle{
  background-color: rgb(3, 56, 100);
  text-align: center;
  padding:30px 0;
  color:white;
  font-size: 18px;
  font-weight: 800;

}
ul.MiniNavi_sub li{
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdcdc;
  transition: all 0.3s;
}
ul.MiniNavi_sub li:hover{
  background-color: #2a9b73;
}
ul.MiniNavi_sub li:hover a{
  color:white;
}
ul.MiniNavi_sub li a{
  padding:10px;
  display: block;
  width:100%;
}
@import url('https://fonts.googleapis.com/css2?family=Orbit&display=swap');
.orbit-regular {
  font-family: "Orbit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* @font-face {
  font-family: 'Orbit-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2310@1.0/Orbit-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} */
ul.MiniNavi_sub li:after{
  content: ">";
  font-family: 'Orbit-Regular';
  position: absolute;
  top:50%;
  transform: translateY(-50%);
  right:10px;
  color:#333;
  font-weight: 800;
}
.detail_cont{
  width:80%;
  max-width: 800px;
  margin:0 auto;
  /* overflow-x: scroll; */
}
.detail_cont > img{
  width:100%;
}
.detail_tit{
  font-size: 30px;
  font-weight: 600;
  padding:30px 0 20px 0;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 30px;
}
.detail_table_wrap{
  width:95%;
  margin:0 auto;
  margin-bottom: 30px;
}
.detail_table_wrap table{
  width:100%;
}
.detail_table_wrap table td{
  padding:15px 10px;
  box-sizing: border-box;
  white-space: nowrap;
}
.detail_table_wrap table td:nth-child(1){
  width:70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail_table_wrap table td:nth-child(2) {
  text-align: center;
  min-width: 100px;
}
.detail_table_wrap table th{
  background-color: #dcdcdc;
  padding:10px 0;
}
.detail_table_wrap table tbody tr{
  border-bottom: 1px solid #dcdcdc;
}
.detail_table_wrap2{
  width:100%;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top:30px;
}
.detail_table_wrap2 table{
  width:100%;
}
.detail_table_wrap2 table tr td{
  padding:10px 10px;
  box-sizing: border-box;
}
.detail_table_wrap2 table tr{
  border-bottom: 1px solid #dcdcdc;
}
.detail_table_wrap2 table tr:nth-child(1){
  border-top: 2px solid rgba(0, 0, 0, 0.497);
}
.detail_table_wrap2 table tr td:nth-child(1){
  background-color: rgba(128, 128, 128, 0.071);
  min-width: 20%;
  max-width: 20%;
  border-right: 1px solid #dcdcdc;
}
.detail_table_wrap2 table tr td:nth-child(2){
  min-width: 80%;
  max-width: 80%;
}
.visit_table_wrap{
  width:100%;
  margin: 0 auto;
}
.visit_table_wrap table{
  width:100%;
  text-align: center;
}
.visit_table_wrap table td{
  padding:10px 0;
  border-bottom: 1px solid #dcdcdc;
}

.payBack_wrapper ul{
  width:100%;
  margin:0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.payBack_wrapper ul li{
  position: relative;
  text-align: center;
  padding:20px;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  max-width: 170px;
}
.payBack_wrapper ul li img{
  width:100%;
}
.event_img_wrap{
  position: relative;
  min-width:130px;
  max-width:130px;
  max-height:130px;
  min-height:130px;
  overflow: hidden;
  border-radius: 8px;
}
.event_img_wrap::after{
  content: "";
  position: absolute;
}
.event_img_wrap img{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width:100%;
  height:100%;
  background-size: cover;
}
.back_btn{
  width:100%;
  margin:0 auto;
  display: block;
  text-align: center;
  margin:0 auto;
}
.back_btn a{
  max-width: 100px;
  display: block;
  background-color: #01C2D1;
  color:white;
  padding:4px;
  box-sizing: border-box;
  margin-left:auto;
  border-radius: 4px;
}
.payBack_wrapper ul li{
  transition: 0.3s;
}
.payBack_wrapper ul li img{
}
.payBack_wrapper ul li:hover{
  opacity: 0.3;
}
.mappedPayTitle{
  color:#9e9e9e;
}
.usedRules{
  margin-bottom: 30px;
}




.brandList_wrap{
  width:100%;
  margin:0 auto;
  margin-bottom: 30px;
}
.brandList_wrap table{
  width:100%;
}
.brandList_wrap table tr{
  cursor: pointer;
}
.brandList_wrap table td{
  padding:15px 10px;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: left;
  max-width:300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.brandList_wrap table th{
  background-color: #dcdcdc;
  padding:10px 0;
}
.brandList_wrap table tbody tr{
  border-bottom: 1px solid #dcdcdc;
}

.brandList_wrap img{
  width:100%;
}
@media screen and (max-width:800px){
  .detail_cont_wrap{
    flex-direction: column;

  }
  .usedRules,
  .privacy-policy{
    width:90%;
    margin:0 auto;
  }
  .MiniTitle{
    background-color: white;
    color:#333;
  }
  .MiniNavi{
    width:100%;
  }
  .detail_cont{
    width:100%;
  }
  .detail_tit{
    display: none;
  }
  .detail_table_wrap2{
    width:90%;
  }
  .back_btn{
    width:90%;
    margin:0 auto;
  }
  .payBack_wrapper ul li{
    width:100%;
    justify-content: center;
  }
  .visit_table_wrap{
    width:90%;
  }
  .pc{
    display: none;
  }
  .mobile{
    display: block;
  }



}

@media screen and (max-width:500px){

.brandList_wrap{
  width:90%;
  margin:0 auto;
  overflow-x: scroll;
}
  .payBack_wrapper ul{
    width:90%;
    justify-content: center;
  }
}
.main_popup {
  background-color: rgba(0, 0, 0, 0.815);
  position: absolute;
  width:100%;
  height:100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 999999999999;
  box-sizing: border-box;
  overflow-y: scroll;
}
/*
.popup button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #0056b3;
} */
.cityModal{
  width:100%;
  height: 100vh;
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 999900000;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.522);
}
.cityModalCont button{

}
.cityModalCont{
  width:90%;
  max-width: 500px;
  max-height: 80vh;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  display: block;
  color: white;
}
.cityModal img{
}
.cityModal .modal_img img{
  width:100%;
  display: block;
}
.close_wrap > div {
  display: flex;
  justify-content: space-between;
  background-color: rgb(30, 30, 30);
  padding:10px;
  box-sizing: border-box;
  align-items: center;
}
.admin_login{
  display: flex;
  position: relative;
  z-index: 20;
  color:white;
  border-radius: 8px;
  padding:20px 30px;
  box-sizing: border-box;
  background-color: gray;
}
.login_button_wrap_modal{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
}
.admin_login_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 300px;
  width: 100%;
  text-align: center;
  border-radius: 10px;
}

.admin_login_modal h2 {
  font-size: 1.5rem;
  text-align: left;
  margin-bottom: 10px;
}

.admin_login_modal input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.admin_login_modal button {
  background: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
.admin_login_modal:nth-child(2){
  margin-left:10px;
}

.admin_login_modal button:hover {
  background: #0056b3;
}
.ShowMemberCount{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin_login_button_wrap{
  display: flex;
  gap:10px;
  align-items: center;
}
.admin_login_button_wrap .gogo_home a{
  color:white;
  padding: 20px 30px;
  background-color: rgb(104, 225, 255);
  box-sizing: border-box;
  border-radius: 8px;
}
.count_table td{
  padding:10px;
  box-sizing: border-box;
}
.admin_table_wrap h2{
  color:white;
  margin-bottom: 10px;
}
.admin_table_wrap{
  min-height: 100vh;
  width:90%;
  max-width: 500px;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.admin_table_wrap table{
  width:100%;
  border:1px solid black;
  background-color: white;
}
.admin_table_wrap table th{
  border:1px solid black;
}
.admin_table_wrap table td{
  border:1px solid black;
  text-align: center;
}
.total_tr{
  background-color: rgb(180, 188, 252);
}
.limit_comment{
  margin-top:5px;
  margin-bottom:10px;
  font-size: 20px;
  font-weight: 800;
  text-align: right;
}

.notice_wapper ul li{
  border-bottom: 1px solid #dcdcdc;
  text-align: center;
  box-sizing: border-box;
}
.notice_wapper ul li a .mappedPayTitle{
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.mappedPayTitle_header{
  display: flex;
  text-align: center;
  font-weight: 800;
  background-color: rgb(221, 221, 221);
  border-radius: 4px;
}

.mappedPayTitle_header  > div:nth-child(1),
.notice_wapper ul li a .mappedPayTitle > div:nth-child(1){
  padding:10px;
  min-width: 5%;
  max-width: 5%;
  text-align: center;
  box-sizing: border-box;
}
.mappedPayTitle_header  > div:nth-child(2),
.notice_wapper ul li a .mappedPayTitle > div:nth-child(2){
  padding:10px;
  min-width: 60%;
  max-width: 60%;
  box-sizing: border-box;
}
.mappedPayTitle_header  > div:nth-child(3),
.notice_wapper ul li a .mappedPayTitle > div:nth-child(3){
  padding:10px;
  min-width: 15%;
  max-width: 15%;
  box-sizing: border-box;
}
.mappedPayTitle_header  > div:nth-child(4),
.notice_wapper ul li a .mappedPayTitle > div:nth-child(4){
  padding:10px;
  min-width: 15%;
  max-width: 15%;
  box-sizing: border-box;
}
.notice_wapper ul li a .mappedPayTitle:hover{
  background-color: #01c3d134;
}
.fixed_logout_btn_wrap{
  position: fixed;
  z-index: 100;
  bottom:10px;
  right:10px;
}
.fixed_logout_btn{
  display: block;
  color:white;
  font-weight: 800;
  box-sizing: border-box;
  border-radius: 30px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: all 0.3s;
}
.fixed_logout_btn:last-child  a{
  background-color: red;
  margin-top:30px;
}
.fixed_logout_btn a{
  background-color: #007bff;
  padding:12px 20px;
  display: block;
  color:white;
  font-weight: 800;
  box-sizing: border-box;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s;
}
.fixed_logout_btn:hover a{
  background-color: #7ebcff;
}
@media screen and (max-width: 1000px) {
  .admin_table_wrap h2{
    color:#000;
  }
  .write_button {
    width: 90% !important;
    margin: 0 auto;
}
  .notice_wapper{
    width:90%;
    margin:0 auto;
  }
  .mappedPayTitle > div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.main_modal h2{
  text-align: left;
  margin-bottom: 10px;
  font-size: 20px;
}
.main_modal table td{
  text-align: left;
}
.manual_wrap{
  border:1px solid #dcdcdc;
  text-align: left;
  padding:20px;
  box-sizing: border-box;
}
.manual_title{
  font-weight: 800;
  margin-bottom: 10px;
}
.manula_img_wrap{
  width:90%;
  max-width: 600px;
}
.manula_img_wrap img{
  width:100%;
}
.big_btn{
  width:100%;
  padding:20px 100px;
  box-sizing: border-box;
  margin:10px 0;
  font-size: 20px;
}
.img-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
}
.img-wrapper img {
  width:100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(50, 50);
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
}
.ql-editor{
  min-height: 20vh;
}
.location_marker{
  display: block;
  padding:5px;
  box-sizing: border-box;
  background-color: white;
  border:1px solid #747474;
  transform: translateY(-29px) scale(1.2);
  text-align: center;
}
.manual_txt{
  display: inline-block;
  margin-left:10px;
  color:#0056b3;
  font-weight: 800;
  font-size: 18px;
}
.button_wrap{
  text-align: right;
}
.button_wrap button{
  box-sizing: border-box;
  font-size: 20px;
  right:10px;
  bottom:10px;
  z-index: 999;
  background-color: rgb(71, 145, 255);
  margin:0 auto;
  margin:30px 0;
  text-align: center;
}
.text_center{
  text-align: center;
}
.section_1{
  position: relative;
}
.button_wrap2 button{
  position: absolute;
  box-sizing: border-box;
  font-size: 20px;
  right:10px;
  top:10px;
  z-index: 999;
  background-color: rgb(71, 145, 255);
  margin:0 auto;
  margin:10px 0;
  text-align: center;
}
.detail_cont{
  position: relative;
}
.button_wrap3 button{
  position: absolute;
  box-sizing: border-box;
  font-size: 20px;
  right:0px;
  top:0px;
  z-index: 999;
  background-color: rgb(71, 145, 255);
  margin:0 auto;
  margin:30px 0;
  text-align: center;
}