.fairCreate_wrap{
    padding:20px;
    box-sizing: border-box;
    margin-bottom: 200px;
}
.fairCreate_wrap .tit{
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 10px;
}
.fair_table_wrap{
    min-width: 1300px;
}
.fair_table_wrap table{
    border:1px solid #dcdcdc;
    text-align: center;
}

.fair_table_wrap table th{
    padding:10px;
    box-sizing: border-box;
    border-bottom: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    color:gray;
}
.fair_table_wrap table td{
    padding:5px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    vertical-align: middle;
    min-width:100px;
}

.datetime{
    border: 1px solid #C1C1C1;
    border-radius: 8px;
    padding: 13px 17px;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.margin_bottom_10{
    margin-bottom: 10px;
}
.fair_setting_tit{
    font-weight: 800;
    padding-right:10px;
    box-sizing: border-box;
}
.fair_setting_div{
    margin-bottom: 10px;
    text-align: left;
}
.fair_setting_div .create_btn{
    width:100%;
    max-width: 1000px;
    margin:0 auto;
    padding:20px 10px;
    box-sizing: border-box;
    margin-top:20px;
    text-align: center;
    font-size: 20px;
    font-weight:800;
}
.fair_setting_div td{
    white-space: nowrap;
}
.fair_img_div{
    overflow: hidden;
}
.fair_img_div img{
    width:100px;
    height: 120px;
}
.delete_btn{
    background-color: red;
}
.upload_img_wrap{
    margin-bottom: 10px;
}
.fair_setting_wrap{
    margin-top:50px;
  }
  .fair_setting_div input[type="file"],
  .fair_setting_div input[type="date"],
  .fair_setting_div input[type="text"]{
    min-width: 500px;
    max-width: 500px;
    box-sizing: border-box;
  }
  .fair_table_wrap table .b1{background-color: rgb(255, 235, 235);}
  .fair_table_wrap table .b2{background-color: rgb(255, 241, 235);}
  .fair_table_wrap table .b3{background-color: rgb(255, 248, 235);}
  .fair_table_wrap table .b4{background-color: rgb(251, 255, 235);}
  .fair_table_wrap table .b5{background-color: rgb(235, 255, 238);}
  .fair_table_wrap table .b6{background-color: rgb(235, 255, 252);}
  .fair_table_wrap table .b7{background-color: rgb(235, 242, 255);}
  .fair_table_wrap table .b8{background-color: rgb(244, 235, 255);}
  .fair_table_wrap table .b9{background-color: rgb(233, 233, 233);}
  .fair_table_wrap table .b10{background-color: rgb(255, 255, 255);}