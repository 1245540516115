
.mobile{
	display: none;
}

.wrapper{
    width:100%;
    min-height: 100vh;
    max-width: 1920px;
    margin:0 auto;
}

.pc_navi .logo_img{
    position: relative;
    top: 0;
    left: 20px;
    z-index: 10;
    display: flex;
    align-items: center;
    max-width: 150px;
}

.logo_img a,
.logo_img a img{
    width:100%;
    display: block;
}

.pc_navi{
    position: relative;
}

.pc_navi .col_group{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
    align-items: center;
    box-shadow: 0px 5px 11px 0px rgba(50,50,50,0.08);
    position: relative;
    z-index: 99;
    height: 100px;
}

.pc_navi .col_group_center{
    display: flex;
    align-items: center;
}

.pc_navi .col_group_left{
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding-left: 15px;
}


.pc_navi ul{
	display: flex;
	justify-content: center;
	gap: 35px;
    align-items: center;
}
.pc_navi ul > li::after{
    content: "|";
}
.category .txt{
	color: #707070;
	font-size: 17px;
	letter-spacing: 0px;
	padding: 0 10px;
	vertical-align: middle;
	margin-top: 10px;
	font-family: 'NotoSansKR-Regular';;
}

a{
    text-decoration: none !important;
}

.category .txt:hover{
	color: #000;
	transition: 0.3s;
	cursor: pointer;
}

.dropdown-button {
    background-color: #FFFFFF;
    font-size: 17px;
    color: #707070;
    letter-spacing: 0px;
    padding: 0 10px;
    vertical-align: middle;
}

.pc_navi .gallery_tit{
	color: #707070;
    font-size: 17px;
    letter-spacing: 0px;
    vertical-align: middle;
    margin-top: 10px;
	font-family: 'NotoSansKR-Regular';
}
/* --- pc 네비 갤러리 드롭다운 메뉴 --- */
.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #141d42;
	min-width: 200px;
    max-width: 200px;
	padding: 5px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 9;
    transition: all 0.3s;
}
.dropdown-content .tit {
	color: #fff;
	padding: 8px;
	text-decoration: none;
	display: block;
	font-size: 14px;
}
.dropdown-button{
    position: relative;
}
.pc_navi .dropdown-button::after{
    content: "";
    display: block;
    width: 1px;
    height: 20px;
    background: #dcdddd;
    position: absolute;
    top: 52%;
    right: -18px;
    transform: translateY(-50%);
}
.pc_navi .dropdown-button a:hover{
    color:#2a9b73;
}
.last_navi::after{
    display: none !important;
}

/* .dropdown_1:after{
    content: "";
    display: block;
    width: 1px;
    height: 20px;
    background: #dcdddd;
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    right: 100px;
}


.dropdown_2:after{
    content: "";
    display: block;
    width: 1px;
    height: 20px;
    background: #dcdddd;
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    right: 226px;
}

.dropdown_3:after{
    content: "";
    display: block;
    width: 1px;
    height: 20px;
    background: #dcdddd;
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    right: 505px;
} 
*/


.pc_navi .dropdown:hover .gallery_tit{color: #000; transition: 0.3s; cursor: pointer;}
.pc_navi .dropdown-content .tit:hover { background-color: #a1c18f; transition: 0.3s;}
.pc_navi .dropdown_1:hover .dropdown-content { display: block;}
.pc_navi .dropdown_2:hover .dropdown-content { display: block;}
.pc_navi .dropdown_3:hover .dropdown-content { display: block;}
/* .pc_navi .dropdown:hover .dropdown-button { background-color: #F8F9FB; } */

/* --------section_1-------- */


.section_2{
    /* background-image: url(../img/main/background_url.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: lighten;
    /* background-color: #FFE8E8; */
}

.background_img img{
    width: 100%;
}

.section_2 .coupon_box{
    display: flex;
    /* padding: 90px 0 90px 0; */
    justify-content: center;
}
.section_2 .coupon_box .coupon_1{
    transition: all 0.3s;
}
.section_2 .coupon_box .coupon_1:hover{
    opacity: 0.4;
}

.section_2 .coupon_box .coupon_2{
    transition: all 0.3s;
}
.section_2 .coupon_box .coupon_2:hover{
    opacity: 0.4;
}

/* ------------section_3--------- */
.section_3_back{
    padding: 20px 0 20px 0;
}

.section_3_img_box{
    margin:0px 20px;
    gap: 20px;
    display: flex;
    justify-content: center;
}

.section_3_img{
    width: 390px;
    /* height: 400px; */
}

.section_3_img img{
    width: 100%;
    /* height: 100%; */
}

/* ------section_4------ */
.section_4{

}

.footer{
    background-color: #484849;
    padding: 20px;
}

.footer ul{
    list-style-type: none;
    padding: 15px 20px;
    margin: 0;
}

.footer ul li{
    color: #fff;
}

.footer ul.foot-util{
    padding-bottom: 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255,255,255,.2);
    position: relative;
}
.footer .foot_login{
    display: flex;
}
.foot-util .sRoll{
    position: relative;
    padding-right: 20px;
    color: #fff;
}

.foot-util .sRoll:not(:last-child):after{
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    width: 2px;
    height: 2px;
    background: #fff;
}

.as01{
    color: #fff;
}

.footer_table{
    background-color: #484849;
    color: #fff;
    padding: 0 20px;
}

.footer_table tr td{
    font-size: 12px;
    line-height: 25px;
}
.swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* 햄버거 아이콘 스타일 */
.hamburger {
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    z-index: 100;
    box-sizing: border-box;
  }
  
  .bar {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.4s;
  }
  
  .mobile_navi{
    position: relative;
    z-index: 9999999;
    background-color: rgb(255, 255, 255);
    box-shadow: 3px 3px 10px #00000012;
    
  }
  /* 모바일 화면에서 햄버거 아이콘 열릴 때의 스타일 */
  .mobile_navi.open .hamburger .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 5px);
  }
  
  .mobile_navi.open .hamburger .bar:nth-child(2) {
    opacity: 0;
  }
  
  .mobile_navi.open .hamburger .bar:nth-child(3) {
    transform: rotate(45deg) translate(-3px, -3px);
  }
  
  /* 모바일 메뉴 스타일 */
  .mobile_navi .dropdown {
    display: none;
    background-color: #fff;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 1;
    list-style: none;
    padding: 0;
  }
  
  .mobile_navi .dropdown.open {
    display: block;
    padding:30px 0px 30px 0px;
    box-sizing: border-box;
    overflow-y:scroll;
    box-shadow: 3px 3px 15px #00000016;
  }
  .mobile_navi .dropdown.open::-webkit-scrollbar{
    display: none;
  }
  .mobile_navi .dropdown li {
    text-align: center;
    box-sizing: border-box;
  }
  
  .mobile_navi .dropdown a {
    text-decoration: none;
    color: #333;
    display: block;
    transition: background-color 0.3s ease;
    display: block;
    padding:5px 0;
  }
  .mobile_navi .gallery_tit{
    padding: 10px;
    display: block;
  }
  .mobile_navi .dropdown a:hover {
    background-color: #f0f0f0;
  }

  .mobile_navi .logo_img{
    position: relative;
  }


@media screen and (max-width: 1000px) {
    .pc{
        display: none;
    }
    .mobile{
        display: block;
        
    }
    .mobile_navi .logo_img{
        text-align: center;
        margin:0 auto;
        max-width: 100px;
        padding:10px 0 10px 20px;
    }
    .section_3_img{
        width: 100%;
    }
    .section_2 .coupon_box{
        width: 100%;
        margin:0 auto;
        flex-direction: column;
        align-items: center;
    }
    .section_3_img_box{
        width:90%;
        margin:0 auto;
        flex-direction: column;
        align-items: center;
    }
    .section_3_img_box img{
        width:100%;
    }
    ul.MiniNavi_sub li{
        font-size: 17px;
    }
    .dropdown-content{
        transition: opacity 0.3s ease, transform 0.3s ease;
    }
    .dropdown-content.open {
        min-width:100%;
        display: block;
        position: relative;
        background-color: #d1d1d100;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0);
        z-index: 9;
        box-sizing: border-box;
        transform: translateY(0);
    }
    .dropdown-content.open a{
        border-bottom:1px solid #dcdcdc;
        background-color: #d1d1d12d;
        padding:13px 0;
    }
    
}