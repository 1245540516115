main.selectPlaceWrap{
    width:100%;
    overflow-x: hidden;
    min-height:100vh;
    background-color:  #6E5DE3;
    background-image: url("../img/selectPlace/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    align-items: flex-start;
    padding:4%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;

}
.selectPlaceWrap nav{
    width:100%;
    position: fixed;
    top:0;
    left:0;
    padding:20px;
    box-sizing: border-box;
    background-color: #ffffff3a;
}
.selectPlaceWrap nav a{
    color:white;
    background-color: #252525;
    padding:10px 20px;
    box-sizing: border-box;
    border-radius: 30px;
}
.selectPlaceDom1,
.selectPlaceDom2{
    box-sizing: border-box;
}

.title_img_wrpa_luvmom{
    margin-bottom: 35px;
}

.title_img_wrap_luvmom_mo{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
}

.title_img_wrap_luvmom_notice{
    font-size: 18px;
    color: #9F9F9F;
    font-weight: 500;
}
.title_img_wrap{
    width:100%;
    max-width: 600px;
    margin-bottom: 8%;
}
.title_img_wrap img{
    width:100%;
    display: block;
}
.select_place_cont{
    width:60%;
}
.select_place_cont ul{
}
.select_place_cont ul li{
    width:100%;
    min-width:350px;
    max-width:350px;
    margin-bottom: 10px;
}
.select_place_cont ul li a{
    display: block;
    width:100%;
}
.select_place_cont ul li a img{
    display: block;
    width:100%;
}
.location_wrap{
    max-width: 600px;
    margin:0 auto;
}
.location_wrap img{
    width:100%;
    display: block;
}
.select_place_cont li{
    border-radius: 25px;
    overflow: hidden;
}
.Link_click{
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    background-color: white;
}
@font-face {
    font-family: 'SBAggroB';
    src: url('/src/static/font/SBAggro/SBAggroM.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SBAggroL';
    src: url('/src/static/font/SBAggro/SBAggroM.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
.Link_title{
    font-family: 'SBAggroB' !important;
    padding: 4% 5.5%;
    box-sizing: border-box;
    font-size: 1.7em;
    font-weight: 400;
    color:white;
    white-space: nowrap;

}

.Link_date{
    /* font-family: 'SBAggroL' !important; */
    font-size: 1.2em;
    margin-left: 4%;
    white-space: nowrap;
    color:white;
}
.no-margin{
    margin-left: -1%;
}
.Link_icon{
    position: relative;
    width:10%;
    border-radius: 50%;
    text-align: right;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: 4%;
}
.Link_icon::after{
    content: "";
    padding-bottom: 100%;
    display: block;
}
.Link_icon img{
    position: absolute;
    box-sizing: border-box;
    padding:10px;
}
.color_1{
    background-color: #8DBBFC;
}
.color_2{
    background-color: #719BD6;
}
.color_3{
    background-color: #2962A5;
}
.color_4{
    background-color: #FFB8E8;
}
.color_5{
    background-color: #FF8C90;
}
.color_6{
    background-color: #FFCE53;
}
.color_7{
    background-color: #E2399E;
}
.color_8{
    background-color: #C150CA;
}
.color_9{
    background-color: #5C58CE;
}
.color_10{
    background-color: #50CAA7;
}
.color_11{
    background-color: #6ED075;
}
.color_12{
    background-color: #FFAA66;
}
.color_13{
    background-color: #7B95FF;
}
.color_14{
    background-color: #A8D1FF;
}
.color_15{
    background-color: #FF6B9D;
}
@media screen and (max-width:1400px) {
    .selectPlaceDom1{
        width:50%;
    }
    .location_wrap{
        margin:0 auto;
    }
    .select_place_cont{
        max-width:500px;
    }
}
@media screen and (max-width:1000px) {
    main.selectPlaceWrap{
        flex-direction: column;
        padding:50px 4%;
    }

    .title_img_wrap{
        width:100%;
        max-width: 90%;
        max-width: auto !important;
        margin:0 auto;
        margin-bottom: 8%;
    }

    .select_place_cont{
        width:100%;
        max-width: auto !important;
        margin:0 auto;
        margin-bottom: 10%;
    }
    .selectPlaceDom1{
        width:100%;
    }
    .select_place_cont ul li{
        margin-bottom: 15px;
        min-width: 350px;
        max-width: 100% !important;
    }
    main.selectPlaceWrap{
        background-image: none;
        background-color: #F6FAFF;
    }

}

@media screen and (max-width:500px) {
    .location_wrap{
        width:93%;
        margin:0 auto;
    }
    .select_place_cont ul li{
        min-width:230px;
    }
    .Link_title{
        font-size: 1.0em;
    }
    .Link_date{
        font-size: 1.1em;
    }

}

.background_star{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
}
.background_star img:nth-child(1){
    max-width: 40%;
}
.background_star img:nth-child(2){
    max-width: 10%;
}
