.write_modal_box{
    position: fixed;
    z-index: 100000000000000;
    max-width:70vw;
    min-width:70vw;
    height:100vh;
    top:0%;
    right:0%;
    background-color: white;
    padding:30px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:10px;
    overflow-y: scroll;
    border:1px solid #dcdcdc;
}
.write_button{
    width:100%;
    margin:0 auto;
    text-align: right;
    margin-bottom:10px;

}
.write_modal_box .write_title{
    font-size: 20px;
    font-weight:800;
}
.button_wrap2{
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:10px;
    margin-bottom:10px;
}
.button_wrap2 button:nth-child(2){
    background-color: red;
}
.write_modal_box .button_wrap{
    width:100%;
    /* max-width: 500px; */
    margin:0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.write_modal_box .button_wrap button{
    width:100%;
}
.write_modal_box .button_wrap button{
    padding:10px 20px;
    box-sizing: border-box;
}
.write_modal_box .button_wrap button:nth-child(2){
    background-color: red;
}
.write_modal_box table td{
    padding:10px;
    box-sizing: border-box;
}
@media screen and (max-width:800px) {
    .write_modal_box{
        max-width: 100%;
        min-width: 100%;
    }
}
@media screen and (max-width:500px) {
    .write_button{
        width:90%;
        margin:0 auto;
    }
}