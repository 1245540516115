.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
}

.complete-modal {
    background: white;
    padding: 0 0 1rem 0;
    /* border-radius: 12px; */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 400px;
    position: relative;
}

.complete-content {
    text-align: center;
}

.complete-title{
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    margin: 1rem auto;
    width: 80%;
}

.complete-link{
    background-color: #FF8363;
    color: #fff;
    font-size: 16px;
    padding: 8px;
    max-height: 40px;
    border: none;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-decoration: none;
    border-radius: 7px;
}

.complete-info{
    color: #000000;
    font-size: 13px;
    font-weight: 300;
    width: 90%;
    margin: 1rem auto;
    line-height: 1.4;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
}
.complete-img-wrap{
    width: 100%;

    img{
        width: 100%;
    }
}

.g_close_btn{
    position: absolute;
    top: 15px;
    right: 15px;
}

.complete-content h2 {
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    color: #333;
    word-break: keep-all;
}

.modal-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.8rem 2rem;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.modal-button:hover {
    background-color: #0056b3;
}