.detail_table_wrap{
}
.category_title{
    width:100%;
    text-align: center;
    background-color: rgb(188, 188, 188);
    border-radius: 4px;
    padding:10px 10px;
    color:white;
    box-sizing: border-box;
}
.category_item_ul li{
    display: flex;
    padding:10px 0;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(128, 128, 128, 0.315);
}
.introduce_wrap{
    background-color: rgb(213, 239, 240);
    padding:50px 20px 35px 20px;
    /* background: linear-gradient(45deg,  rgba(176,226,229,1) 0%,rgba(198,203,242,1) 74%,rgba(232,235,255,1) 100%);  */
    background-image: url('../../static/img/Introduce/introduce_back.png');
    background-size: cover;
    background-repeat: no-repeat;

}
.item_title{
    font-weight: 700;
    min-width:100px;
}
.introduce_table_wrap{
    width:90%;
    max-width: 600px;
    margin:0 auto;
    background-color: rgba(255, 255, 255, 0.678);
    border-radius: 10px;
    
}
.introduce_table_wrap table{
    width:90%;
    
    margin:0 auto;
}
.introduce_table_wrap table tr{
    border-bottom: 1px solid rgba(138, 138, 138, 0.24);
}
.introduce_table_wrap table tr:last-child{
    border-bottom: none;
}
.introduce_table_wrap table tr td:nth-child(1){
    text-align: right;
    padding:35px 5px;
    font-size: 20px;
    white-space: nowrap;
    box-sizing: border-box;
}
.introduce_table_wrap table tr  td:nth-child(2){
    padding:20px;
    font-size: 20px;
    font-weight: 800;
}
.introduce_detail_wrap{
    max-width: 600px;
    width:90%;
    margin:0 auto;
    background-color: rgba(255, 255, 255, 0.678);
    border-radius: 10px;
    margin-top:30px;
    padding:20px;
    box-sizing: border-box;
}
.introduce_title{
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 10px;
}
.introduce_desc{
    font-size: 25px;
}
.introduce_main_wrap{
}
.introduce_title_img_wrap{
    width:80%;
    max-width: 600px;
    margin:0 auto;
    margin-bottom: 30px;
}
.introduce_title_img_wrap img{
    width:100%;
}
.contents_div{
    width:100%;
    margin:0 auto;
    padding-bottom: 30px;
}
.contents_div img{
    width:100%;
    margin-bottom: 20px;
}
.hit{
    text-align: right;
    margin-bottom: 4px;
}
@media screen and (max-width : 800px) {
    .contents_div{
        width:90%;
        margin:0 auto;
        padding-bottom: 30px;
    }
    .introduce_table_wrap table tr td:nth-child(1){
        font-size: 4vw;
    }
    .introduce_table_wrap table tr  td:nth-child(2){
        font-size: 4vw;
    }
    .introduce_title{
        font-size: 3.5vw;
    }
    .introduce_desc{
        font-size: 3.5vw;
    }  
}


@media screen and (max-width : 600px) {
  
    .introduce_table_wrap table tr td:nth-child(1){
        font-size: 3.3vw;
        padding:10px 5px;
    }
    .introduce_table_wrap table tr  td:nth-child(2){
        font-size: 3.3vw;
    }
    .introduce_title{
        font-size: 3vw;
    }
    .introduce_desc{
        font-size: 3vw;
    }  
    .contents_div{
        width:90%;
        margin:0 auto;
    }
}
.sumnail_img{
    width:300px;
    max-height: 150px;
    overflow: scroll;

}
.sumnail_img img{
    width:100%;
}

.example_table{
    border:1px solid #dcdcdc;
}
.example_table th,
.example_table td{
    border:1px solid #dcdcdc;
}
.example_table_title{
    font-size: 12px;
    font-weight: 700;
    color:#a1a1a1;
    margin-top:20px;
    margin-bottom: 4px;
}
.example_table td{
    width:130px;
}
.detail_cont_wrap .close_btn{
    position: absolute;
    right:10px;
    top:10px;
    background-color: red;
    padding:10px 20px;
    box-sizing: border-box;
}